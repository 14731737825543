import {Text, View} from "@react-pdf/renderer";
import React from "react";
import { TableRow } from "../product-table/OfferProductPdfTableHeader";
import {StyleSheet} from "@react-pdf/renderer";
import {getI18Text} from "../../../../../../../i18/i18label";

const styles = StyleSheet.create({
    col1H: { width: 580, backgroundColor: "white", textAlign: "left", padding: 2, paddingLeft: 22 },
    col2H: { width: 300, backgroundColor: "white", textAlign: "center", padding: 2, },
    col3H: { width: 80, backgroundColor: "white", textAlign: "center", padding: 2, },
    col4H: { width: 80, backgroundColor: "white", textAlign: "center", padding: 2, },
    col5H: { width: 100, backgroundColor: "white", textAlign: "center", padding: 2, },
    col6H: { width: 80, backgroundColor: "white", textAlign: "center", padding: 2, },
    col7H: { width: 80, backgroundColor: "white", textAlign: "center", padding: 2, },
});

const OfferProductPdfTableHeader = () => {
    return (
        <View style={{ fontWeight: "bold" }}>
            <TableRow cols={[
                <View style={styles.col1H}>
                    <Text>{ getI18Text("ДОПЪЛНИТЕЛНИ РАЗХОДИ") }:</Text>
                </View>,
                <View style={styles.col3H}>
                    <Text>{ getI18Text("Ед.цена") }</Text>
                </View>,
                <View style={styles.col4H}>
                    <Text>{ getI18Text("Отстъпка") }:</Text>
                </View>,
                <View style={styles.col5H}>
                    <Text>{ getI18Text("Цена след TO") }:</Text>
                </View>,
                <View style={styles.col6H}>
                    <Text>{ getI18Text("Кол.") }</Text>
                </View>,
                <View style={styles.col7H}>
                    <Text>{ getI18Text("Цена") }</Text>
                </View>
            ]}/>
        </View>
    );
}

export default OfferProductPdfTableHeader;
