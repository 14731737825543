import {Button, CloseButton, Col, Container, Modal, Row, Spinner, Table} from "react-bootstrap";
import {
    capitalizeFirstLetter,
    dateToString,
    zeroToEmptyStringAsCurrencyWithCommas,
    zeroToEmptyStringAsNumber
} from "../../../../common";
import ModalBackground from "../../../../common/ModalBackground";
import CenterAlignedCell from "../../../../common/CenterAlignedCell";
import RightAlignedCell from "../../../../common/RightAlignedCell";
import {priceToText} from "../../../../priceToText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf, faPrint} from "@fortawesome/free-solid-svg-icons";
import {useReactToPrint} from "react-to-print";
import React, {useEffect, useMemo, useRef, useState} from "react";
import "./WarehouseRequestPrintPreview.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CustomPrintSendEmail from "../../../../common/custom-print-elements/CustomPrintSendEmail";
import {API} from "../../../../api";
import {
    WarehouseRequestType,
    WarehouseRequestTypeItem
} from "../../warehouse-transactions/warehouse-request/WarehouseRequestType";
import I18Label from "../../../../i18/i18label";

interface Props {
    zIndex?: number;
    warehouseRequest: WarehouseRequestType;
    onDismiss: () => void;
}


export default function WarehouseRequestPrintPreview({zIndex, warehouseRequest, onDismiss}: Props) {
    const _zIndex = zIndex || 1000;
    const componentRef = useRef(null);
    const [working, setWorking] = useState(false);

    let emailHTML = "<div>Здравейте,</div>" +
        "<div>&nbsp;</div>" +
        "<div>Прикачен е файл със заявка за доставка.</div>" +
        "<div>&nbsp;</div>" +
        "<div>&nbsp;</div>" +
        "<div>Поздрави,</div>" +
        "<div>&nbsp;</div>" +
        "<div>" + API.user?.name + "</div>" +
        "<div>" + API.user?.email + "</div>";

    const warehouseRequestItems: WarehouseRequestTypeItem[] = [];
    warehouseRequest.items.forEach((item) => {
        const existingItem = warehouseRequestItems.find((i) => i.product.id === item.product.id);
        if (existingItem) {
            existingItem.qty += item.qty;
        } else {
            warehouseRequestItems.push({...item});
        }
    });

    const printContent = (
        <Container fluid ref={componentRef} className={"p-3"}>
            <div className={"mb-5 text-center"}>
                <img src={require('../../../../assets/images/logo.png')} className={"w-75"}/>
            </div>
            <Row className={"text-center mb-5"}>
                <h1 className={"text-uppercase"}>ЗАЯВКА ЗА ДОСТАВКА</h1>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <h4>Номер:</h4>
                </Col>
                <Col xs={"auto"} className={"text-end fw-bold"}>
                    <h4><b>{warehouseRequest?.requestNumber?.toString().padStart(10, '0')}</b></h4>
                </Col>
                <Col/>
                <Col xs={"auto"}>
                    <h4>Доставчик:</h4>
                </Col>
                <Col xs={"auto"}>
                    <h4><b>{warehouseRequest.provider?.name}</b></h4>
                </Col>
            </Row>

            <Row>
                <Col className={"border border-2 border-dark rounded pt-2 m-3 me-0"}>
                    <Table className={"no-border"}>
                        <thead>
                        <th>SKU</th>
                        <th className={"w-50"}>Продукт</th>
                        <th>М.Е.</th>
                        <th className={"text-end"}>Кол.</th>
                        <th>Очаквана<br/>доставка</th>
                        </thead>
                        <tbody>
                        {
                            warehouseRequestItems.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.product.sku}</td>
                                        <td>{item.product.name}</td>
                                        <td>{item.product.dimension}</td>
                                        <td className={"text-end"}>{zeroToEmptyStringAsNumber(item.qty)}</td>
                                        <td>{dateToString(item.expectedDeliveryDate)}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>


        </Container>
    );

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function handleClose() {
        onDismiss();
    }

    const handleExport = async () => {
        if (componentRef.current) {
            const pdf = new jsPDF("p", "pt", "a4");
            const canvas = await html2canvas(componentRef.current);
            const img = canvas.toDataURL("image/png");
            const imgProperties = pdf.getImageProperties(img);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width;
            pdf.addImage(
                img,
                "PNG",
                0,
                0,
                pdfWidth - 5,
                pdfHeight,
                "page1",
                "FAST",
            );
            return pdf;
        }
    };

    const handleGerenatePdf = async () => {
        setWorking(true);
        const pdf = await handleExport();
        if (pdf) {
            pdf.save(`confirmation-${warehouseRequest.requestNumber?.toString() ?? "xxx"}.pdf`);
        }
        setWorking(false);
    };

    const [pdfFile, setPdfFile] = useState<string | undefined>(undefined);
    useEffect(
        () => {
            const fetchData = async () => {
                const pdf = await handleExport();
                if (pdf) {
                    const content = pdf.output('arraybuffer');
                    var base64 = btoa(
                        new Uint8Array(content)
                            .reduce((data, byte) => data + String.fromCharCode(byte), ''));
                    setPdfFile(base64);
                }
            };
            fetchData();
        },
        [componentRef.current]
    );

    return (
        <ModalBackground zIndex={_zIndex}>
            <Modal show style={{zIndex: _zIndex + 1}} size={"xl"}>
                <Modal.Header>
                    <Modal.Title>Печат на документ</Modal.Title>
                    <CloseButton onClick={() => onDismiss()}/>
                </Modal.Header>
                <Modal.Body className={"p-5"}>
                    {printContent}
                </Modal.Body>
                <Modal.Footer>
                    {
                        working ? <Col className={"text-center"}><Spinner animation={"border"}/></Col> :
                            <>
                                <Col>
                                    <Button variant="secondary" onClick={handleClose}>
                                        <I18Label label={"Затвори"}/>
                                    </Button>
                                </Col>
                                <Col>
                                    {
                                        pdfFile &&
                                        <CustomPrintSendEmail
                                            email={emailHTML}
                                            subject={`Нова заявка от Индустриални батерии АД`}
                                            // templateName={"email_with_attached_document"}
                                            attachments={{
                                                filename: `confirmation-${warehouseRequest.requestNumber?.toString() ?? ""}.pdf`,
                                                blob: pdfFile,
                                            }}
                                            initialSendToEmail={warehouseRequest.provider?.email ?? ""}/>
                                    }
                                </Col>
                                <Col xs={"auto"}>
                                    <Button onClick={handleGerenatePdf} variant={"info"} className={"text-light"}>
                                        <FontAwesomeIcon icon={faFilePdf}/> <I18Label label={"Запази като PDF"}/>
                                    </Button>&nbsp;
                                    <Button variant="primary" onClick={handlePrint}>
                                        <FontAwesomeIcon icon={faPrint} className={"me-2"}/>
                                        <I18Label label={"Печат"}/>
                                    </Button>
                                </Col>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </ModalBackground>
    );
}
