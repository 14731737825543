import {DocumentReference, FirestoreError, QueryConstraint, QueryDocumentSnapshot, where} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";
import {ProviderType} from "../../nomenclatures/providers/hooks/useProviders";
import {firebaseDateToDate} from "../../../common";
import {WarehouseItemsType} from "./useWarehouseInItems";


export interface WarehouseInType {
    id: string;
    ref: DocumentReference;
    warehouseId: string;
    transactionNumber: number;
    transactionDate: Date;
    provider?: ProviderType;
    items?: WarehouseItemsType;
    productIds?: string[];
    invoiceNumber?: string;
    price: number;
    hasDDS?: boolean;
    isEUR?: boolean;
}

export type WarehouseInTypes = WarehouseInType[];


export function convertWarehouseInData(_objects: QueryDocumentSnapshot[]): WarehouseInTypes {
    if(_objects) {
        return _objects.map(
            d => {
                return {
                    id: d.id,
                    ref: d.ref,
                    transactionNumber: d.get('transactionNumber'),
                    transactionDate: firebaseDateToDate(d.get('transactionDate')),
                    provider: d.get('provider'),
                    items: d.get('items'),
                    productIds: d.get('productIds') ?? [],
                    warehouseId: d.get('warehouseId'),
                    invoiceNumber: d.get('invoiceNumber'),
                    price: d.get('price') || 0,
                    hasDDS: d.get('hasDDS') ?? false,
                    isEUR: d.get('isEUR') ?? true,
                } as WarehouseInType;
            }
        );
    } else return [];
}



export default function useWarehouseIn(warehouseId: string, constraints?: QueryConstraint[]): [WarehouseInTypes, boolean, FirestoreError | undefined] {

    const finalConstraints = constraints ? [...constraints] : [];
    finalConstraints.push(where("warehouseId", "==", warehouseId));

    const [documents, loading, error] = useFirebaseCollection("warehouseIn", finalConstraints);

    const [convertedData, setConvertedData] = useState<WarehouseInTypes>([]);


    useEffect(() => {
        if(documents) {
            setConvertedData(convertWarehouseInData(documents.docs));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
