import {Account} from "./types";
import {collection, getDoc, getDocs, query, where} from "firebase/firestore";
import {fbDb} from "./App";


export const API_CLASS = class {
    public user: Account|null = null;
    public userId: string | null = null;
    public currentUser: any = null;

    async getProductionCardByNumber(newProductionCardNumber: string) {
        let number = parseInt(newProductionCardNumber);
        if (isNaN(number)) {
            number = -1;
        }
        return getDocs(query(collection(fbDb, "productionCards"), where("cardNumber", "==", number)));
    }
}

export const API = new API_CLASS();
