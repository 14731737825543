import {CustomerOfferServicesType} from "../../../../../../../fb-converters/cutomerOffer-converter";
import React from "react";
import OfferServicesPdfTableHeader from "./OfferServicesPdfTableHeader";
import OfferServicesPdfTableRow from "./OfferServicesPdfTableRow";
import {View} from "@react-pdf/renderer";

interface OfferPdfTableProps {
    services: CustomerOfferServicesType,
    productQty: number,
    euroExchangeRate: number
}

const OfferProductPdfTable = ({services, productQty, euroExchangeRate}: OfferPdfTableProps) => {
    return services.length > 0 ? (<View wrap={false}>
        <OfferServicesPdfTableHeader/>
        <OfferServicesPdfTableRow services={services} productQty={productQty} euroExchangeRate={euroExchangeRate} />
    </View>) : null;
};

export default OfferProductPdfTable;
