import {Button} from "react-bootstrap";
import {fbDb} from "../../App";
import {collection, getDocs, limit, orderBy, query, updateDoc, where} from "firebase/firestore";

export  default  function HomePage() {
    return <>
        {/*<Button onClick={*/}
        {/*    async () => {*/}
        {/*        const dataCollection = query(*/}
        {/*            collection(fbDb, 'customerInvoices'),*/}
        {/*            where("inqueryNumber", "!=", null),*/}
        {/*            orderBy("inqueryNumber", "desc"),*/}
        {/*        );*/}
        {/*        const querySnapshot = await getDocs(dataCollection);*/}
        {/*        for(const doc of querySnapshot.docs) {*/}
        {/*            console.log(doc.id, " => ", doc.data());*/}

        {/*            if(doc.data().inqueryId !== doc.data().inquiryId) {*/}
        {/*                await updateDoc(doc.ref, {*/}
        {/*                    inquiryId: doc.data().inqueryId,*/}
        {/*                    inquiryNumber: doc.data().inqueryNumber,*/}
        {/*                    inquiryDate: doc.data().inqueryDate,*/}
        {/*                });*/}
        {/*                console.log("Updated", doc.id);*/}
        {/*                console.log("====================================");*/}
        {/*            }*/}
        {/*        };*/}
        {/*    }*/}
        {/*}>Update customerInvoices</Button>*/}


        {/*<Button onClick={*/}
        {/*    async () => {*/}
        {/*        const dataCollection = collection(fbDb, 'customerInquiries');*/}
        {/*        const querySnapshot = await getDocs(dataCollection);*/}
        {/*        for(const doc of querySnapshot.docs) {*/}
        {/*            console.log(doc.id, " => ", doc.data());*/}

        {/*            if(doc.data().inqueryId !== doc.data().inquiryId) {*/}
        {/*                await updateDoc(doc.ref, {*/}
        {/*                    inquiryId: doc.data().inqueryId,*/}
        {/*                    inquiryNumber: doc.data().inqueryNumber,*/}
        {/*                    inquiryDate: doc.data().inqueryDate,*/}
        {/*                });*/}
        {/*                console.log("Updated", doc.id);*/}
        {/*                console.log("====================================");*/}
        {/*            }*/}
        {/*        };*/}
        {/*    }*/}
        {/*}>Update customerInquiries</Button>*/}
    </>
}
