import {Text, View} from "@react-pdf/renderer";
import {styles} from "./OfferPdfStyles";
import {API} from "../../../../../../api";
import React, {FC} from "react";
import {HeaderLine} from "./OfferPdfDocument";
import {getI18Text} from "../../../../../../i18/i18label";

interface OfferPdfDeliveryConditionsProps {
    comment: string;
    paymentMethod: string;
    validityPeriod: string;
    i18: string[];
    lng: string;
}

const OfferPdfDeliveryConditions: FC<OfferPdfDeliveryConditionsProps> =
    ({paymentMethod, validityPeriod, comment, i18, lng}) => (
        <View wrap={false} style={{marginTop: 10, marginBottom: 10}}>

            <HeaderLine text1={`${i18[5] // УСЛОВИЯ ЗА ДОСТАВКА 
            } :`}/>
            <View wrap={false} style={{flexDirection: "row"}}>
                <Text style={styles.deliveryItem}>
                    &bull; {i18[6]}: </Text>
                <Text style={{fontWeight: "bold"}}> {getI18Text(paymentMethod)}</Text>
            </View>
            <View wrap={false} style={{flexDirection: "row"}}>
                <Text style={styles.deliveryItem}>
                    &bull; {i18[7]}: </Text>
                <Text style={{fontWeight: "bold"}}> {validityPeriod}</Text>
            </View>
            <Text style={styles.deliveryItem}>
                &bull; {i18[8] // ВСИЧКИ ПОСОЧЕНИ ЦЕНИ СА В ЛЕВА БЕЗ ВКЛЮЧЕН ДДС
            }
            </Text>
            <Text style={styles.deliveryItem}>
                &bull; {i18[9] // СРОКЪТ НА ДОСТАВКА Е В РАБОТНИ ДНИ
            }
            </Text>
            {/*{*/}
            {/*    lng === "bg" &&*/}
            {/*    <Text style={styles.deliveryItem}>*/}
            {/*        &nbsp;*/}
            {/*    </Text>*/}
            {/*}*/}
            <Text style={styles.deliveryItem}>
                {i18[10] // ЗАБЕЛЕЖКИ:
            }
            </Text>
            <View style={{paddingTop: 5, paddingLeft: 10}}>
                <Text style={styles.deliveryItem}>
                    {comment}
                </Text>
            </View>
            <View style={{padding: 10}}>

                <Text style={styles.deliveryItem}>
                    {i18[12]  // МОЛЯ, НЕ СЕ КОЛЕБАЙТЕ ДА ЗАДАДЕТЕ ВСИЧКИ ВЪЗНИКНАЛИ ВЪПРОСИ.
                    }
                </Text>
                <Text style={styles.deliveryItem}>
                    {i18[13] // ОСТАВАМЕ В ОЧАКВАНЕ НА ВАШЕТО РЕШЕНИЕ.
                    }
                </Text>
                <Text style={styles.deliveryItem}> </Text>
                <Text style={styles.deliveryItem}>
                    {i18[14] // С УВАЖЕНИЕ
                    }
                </Text>
                <Text style={styles.deliveryItem}> </Text>
                <Text style={styles.deliveryItem}>
                    {API.user?.positionName.toUpperCase()}: {API.user?.name?.toUpperCase()} ( {API.user?.phone} )
                </Text>
            </View>
        </View>
    );

export default OfferPdfDeliveryConditions;
