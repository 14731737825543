export const i18bg = [
    "ДО: ",
    "НА ВНИМАНИЕТО НА: ",
    "УВАЖАЕМИ/А: ",
    "ИМАМЕ УДОВОЛСТВИЕТО ДА ПРЕДСТАВИМ НА ВАШЕТО ВНИМАНИЕ СЛЕДНАТА ОФЕРТА:",
    "Калкулация на месечната инвестиция за рамките на гаранционния срок:",
    "Допълнителна информация",
    "НАЧИН НА ПЛАЩАНЕ",
    "ВАЛИДНОСТ НА ОФЕРТАТА",
    "ВСИЧКИ ПОСОЧЕНИ ЦЕНИ СА В ЛЕВА БЕЗ ВКЛЮЧЕН ДДС",
    "Срокът на доставка е в работни дни и е съобразен с текущата наличност на продуктите",
    "",
    "",
    "МОЛЯ, НЕ СЕ КОЛЕБАЙТЕ ДА ЗАДАДЕТЕ ВСИЧКИ ВЪЗНИКНАЛИ ВЪПРОСИ.",
    "ОСТАВАМЕ В ОЧАКВАНЕ НА ВАШЕТО РЕШЕНИЕ.",
    "С УВАЖЕНИЕ",
    "ТРАНСПОРТ",
    "МОНТАЖ/ДЕМОНТАЖ",
    "ЗАРЕЖДАНЕ",
    "ИЗКУПУВАНЕ",
    "ДЕПОНИРАНЕ",
    "Подробно описание на услугата „УДЪЛЖЕНА ГАРАНЦИЯ“ Цена",
    "Условия на удължената гаранция:",
    "- Удължената гаранция се отнася само за тягови батерии.",
    "- Удължената гаранция се предлага на новозакупени продукти или закупени до шест месеца от дата на сключване на договор за удължаване на гаранцията.",
    "- Удължената гаранция е за продукти закупени заедно със зарядно устройство от Индустриални Батерии ЕООД.",
    "- При експлоатация на продуктите от лек до тежък едносменен дневен режим на работа.",
    "- Стриктно спазване на предписанията за експлоатиране на продуктите.",
    "- Задължително извършване на ежегодно обслужване на продуктите от сервиз на Индустриални Батерии ЕООД.",
    "- При нужда от сервизиране на тягова батерия в сервиз на Индустриални Батерии ЕООД, важат условия и цени по стандартна ценова листа за сервизни услуги.",
    "Подробно описание на услугата „АБОНАМЕНТНА ПОДДРЪЖКА“ Цена",
    "Описание на включените услуги в абонаментно обслужване:",
    "- Първоначално след подписване на договор за абонаментно обслужване:",
    "- Първоначален анализ на текущата експлоатация и поддръжка на тяговите батерии.",
    "- Консултация и предписание относно правилна експлоатация на продуктите, нужда от превантивни и коригиращи действия.",
    "- Обучение на обслужващия персонал относно правилна експлоатация на продуктите.",
    "- При всяко посещение:",
    "• Акумулаторни батерии (по-долу в текста АБ):",
    "- Пускане в експлоатация на нова АБ.",
    "- Проверка на техническото състояние на АБ.",
    "- Ремонт АБ.",
    "• Зарядни устройства (по-долу в текста ЗУ).",
    "- Пускане в експлоатация (настройка на конфигурацията, проверка на работоспособността).",
    "- Почистване, включително и на скрити кухини.",
    "- Ремонт на ЗУ.",
    "- Снемане на данните в паметта, изготвяне на отчет/справка.",
    "- Транспорт на сервизния екип до адрес на клиента.",
    "- При нужда от сервизиране на тягова батерия в сервиз на Индустриални Батерии ЕООД, важат условия и цени по стандартна ценова листа за сервизни услуги,",
    "Приложение 5 към Договор за техническо абонаментно обслужване.",
    "Оферта",
    "Дата",
    "Допълнителна информация към офертата:",
    "Техническа скица на батерията (прикачен файл)",
    "Защо да изберете „Индустриални батерии“ ЕООД за ваш доставчик:",
    "Оптимален експлоатационен срок на батерията и изгодна обща цена на притежание",
    "Над 20 години опит в производството на индустриални батерии",
    "Световни сертификати ISO 9001:2015 и ISO 14001:2015",
    "Качествени компоненти от европейски доставчици",
    "Финално асемблиране в България",
    "Безупречно гаранционно обслужване",
    "За повече информация",
    "Цена за еднократно посещение:",
    "",
    "",
];

export const i18ro = [
    "CĂTRE: ",
    "IN ATENTIA: ",
    "STIMATE/STIMATĂ: ",
    "Avem plăcerea să vă prezentăm în atenția dumneavoastră URMĂTOAREA OFERTA:",
    "Calculul investiției lunare pentru perioada de garanție:",
    "Informații suplimentare",
    "Modalitatea de plată",
    "Valabilitatea ofertei de preț",
    "TOATE PREȚURILE SUNT ÎN EURO FĂRĂ TVA",
    "Termenul de livrare este in zile lucratoare si este in functie de disponibilitatea produselor din momentul confirmarii comenzii",
    "• GARANTIA SE ACORDA PENTRU UN SET COMPLET DE ELEMENTI",
    "Observatii",
    "VĂ RUGĂM SĂ NE CONTACTAȚI PENTRU ORICE ÎNTREBĂRI SUPLIMENTARE.",
    "ASTEPTĂM DECIZIA DVS.",
    "Cu respect,",
    "TRANSPORT",
    "MONTARE / DEMONTARE",
    "ÎNCĂRCARE",
    "RĂSCUMPĂRARE",
    "RECICLARE",
    "Descrierea detaliată a serviciului „GARANȚIE EXTINSĂ” Preț",
    "Termenii garanției extinse:",
    "- Garanția extinsă se aplică numai bateriilor de tracțiune.",
    "- Garantia extinsa se oferă la produsele nou achiziționate sau achiziționate până la șase luni de la data încheierii contractului de prelungire a garanției.",
    "- Garanția extinsă este pentru produsele achiziționate împreună cu un încărcător de la Industrial Batteries SRL.",
    "- Funcționarea produselor într-un mod de funcționare de zi cu un singur schimb ușor până la greu.",
    "- Respectarea strictă a instrucțiunilor de utilizare a produselor.",
    "- Verificarea anuală obligatorie a produselor de către departamentul de service Baterii Industriale SRL.",
    "- În cazul în care o baterie de tracțiune trebuie reparată în atelierul de baterii industriale SRL, se aplică condițiile și prețurile conform listei standard de prețuri pentru serviciile de service.",
    "Descrierea detaliată a serviciului „MENTENANȚĂ ABONAMENT” Preț",
    "Descrierea serviciilor incluse în serviciul de abonament:",
    "- Inițial după semnarea unui contract de servicii de abonament:",
    "- Analiza initiala a functionarii si intretinerii bateriilor de tractiune.",
    "- Consultanta si consiliere privind functionarea corecta a produselor, necesitatea actiunilor preventive si corective.",
    "- Instruirea personalului clientului privind utilizarea corecta a produselor.",
    "- La fiecare vizită:",
    "    • Baterii de tracțiune:",
    "- Punerea în funcţiune.",
    "- Verificarea starii tehnice.",
    "- Reparatie.",
    "• Redresoare.",
    "- Punerea în funcțiune (setarea configurației, verificarea funcționării).",
    "- Curațare.",
    "- Reparatie.",
    "- Descărcare datele în memorie, pregătire raport.",
    "- Vizită de către echipa de departamentul de service la adresa clientului.",
    "- În cazul în care o baterie de tracțiune trebuie reparată în serviciul Bateriilor industriale SRL, se aplică condițiile și prețurile conform listei standard de prețuri pentru serviciile de service,",
    "Anexa 5 la Acordul de servicii de abonament tehnic.",
    "OFERTĂ",
    "DATA",
    "Informații suplimentare la ofertă:",
    "",
    "Dece sa alegeti Industrial Batteries:",
    "Durată de viață optimă a bateriei și cost mic",
    "Peste 20 de ani de experiență în fabricarea bateriilor industriale",
    "Certificate mondiale ISO 9001:2015 și ISO 14001:2015",
    "Componente de calitate de la furnizori europeni",
    "Asamblare finală în Bulgaria",
    "Serviciu de garanție impecabil",
    "Pentru mai multe informații",
    "Preț pentru o singură vizită:",
];



















