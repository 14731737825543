import { CustomerOfferType
} from "../../../../../../../fb-converters/cutomerOffer-converter";
import {TableRow} from "../product-table/OfferProductPdfTableHeader";
import React, {useMemo} from "react";
import {View, Text, StyleSheet} from "@react-pdf/renderer";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../../../../../global-state/global-db";
import {calculateOfferSummary, ProductSummaryType} from "../../../../CustomerOfferDetailsSummary";
import RightAlignedCell from "../../../../../../../common/RightAlignedCell";
import {zeroToEmptyString, zeroToEmptyStringAsCurrencyWithCommas} from "../../../../../../../common";
import {getI18Price} from "../../../../../../../i18/i18label";


const styles = StyleSheet.create({
    col1: { width: 20, textAlign: "center", padding: 2 },
    col2: { width: 320, textAlign: "left", padding: 2 },
    col3: { width: 300, textAlign: "center", padding: 2 },
    col4: { width: 300, textAlign: "center", padding: 2 },
    col5: { width: 80, textAlign: "center", padding: 2 },
});

const OfferInvestmentPdfTableRow = ({offer, summary}:
                                        {
                                            offer: CustomerOfferType,
                                            summary?: ProductSummaryType[] | undefined
                                        }) => {

    return (
        <View>
            {
                summary?.map(
                    (s, idx) => <tr key={idx}>

                        <RightAlignedCell>  </RightAlignedCell>
                        <RightAlignedCell>  </RightAlignedCell>
                        <RightAlignedCell>  </RightAlignedCell>
                        <RightAlignedCell>  </RightAlignedCell>
                        <RightAlignedCell>  </RightAlignedCell>
                        <RightAlignedCell>  </RightAlignedCell>
                    </tr>
                )
            }
            {
                summary?.map((s, idx) => (
                    <TableRow cols={[
                        <View style={styles.col1}>
                            <Text>{ idx+1 }</Text>
                        </View>,
                        <View style={styles.col2}>
                            <Text>{ s.product }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ zeroToEmptyString(s.warranty) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ zeroToEmptyStringAsCurrencyWithCommas(getI18Price(s.priceWithoutServices, offer.eurExchangeRate).toString()) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ zeroToEmptyString(s.extendedWarranty) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ zeroToEmptyStringAsCurrencyWithCommas(getI18Price(s.priceWithExtWarranty, offer.eurExchangeRate).toString()) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ zeroToEmptyString(s.warranty) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ zeroToEmptyStringAsCurrencyWithCommas(getI18Price(s.monthPriceWithoutServices, offer.eurExchangeRate).toString()) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ zeroToEmptyString(s.extendedWarranty) }</Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>{ zeroToEmptyStringAsCurrencyWithCommas(getI18Price(s.monthPriceWithExtWarranty, offer.eurExchangeRate).toString()) }</Text>
                        </View>,
                    ]}/>
                ))
            }
        </View>
    );
};

export default OfferInvestmentPdfTableRow;
