import useFirebaseCollection from "../../../../hooks/useFirebaseCollection";
import {addDoc, collection, doc, QueryConstraint, updateDoc, where} from "firebase/firestore";
import {useEffect, useState} from "react";
import {fbDb} from "../../../../App";
import {WarehouseRequestType, WarehouseRequestTypeItem} from "./WarehouseRequestType";

export interface UseWarehouseRequestsRepo {
    createdAtFrom: Date | null | undefined;
    createdAtTo: Date | null | undefined;
    isActive: boolean;
}

export const warehouseRequestConverter = {
    toFirestore: (data: WarehouseRequestType) => {
        return {
            requestNumber: data.requestNumber,
            provider: data.provider,
            items: data.items,
            itemIds: data.itemIds,
            createdAt: data.createdAt,
            createdBy: data.createdBy,
            isActive: data.isActive
        }
    },
    fromFirestore: (snap: any) => {
        const item = snap.data();

        return {
            id: snap.id,
            requestNumber: item.requestNumber,
            provider: item.provider,
            items: item.items?.map(
                (i: any) => {
                    const item: WarehouseRequestTypeItem = {
                        product: i.product,
                        qty: i.qty,
                        inquiryNumber: i.inquiryNumber,
                        expectedDeliveryDate: i.expectedDeliveryDate?.toDate(),
                        deliveryPrice: i.deliveryPrice,
                        isActive: i.isActive,
                        deliveryQty: i.deliveryQty,
                        invoiceDate: i.invoiceDate?.toDate(),
                        invoiceNumber: i.invoiceNumber,
                        deliveryDate: i.deliveryDate?.toDate(),
                    }

                    return item;
                }) ?? [],
            itemIds: item.itemIds ?? [],
            createdAt: item.createdAt?.toDate(),
            createdBy: item.createdBy,
            isActive: item.isActive ?? false,
        } as WarehouseRequestType;
    }
};


export const useWarehouseRequestsRepo = (
    {
        createdAtFrom,
        createdAtTo,
        isActive
    }: UseWarehouseRequestsRepo
) => {

    const mapConditions = () => {
        let _conditions: QueryConstraint[] = [];
        if (createdAtFrom) {
            _conditions.push(where("createdAt", ">=", createdAtFrom));
        }
        if (createdAtTo) {
            _conditions.push(where("createdAt", "<=", createdAtTo));
        }
        _conditions.push(where("isActive", "==", isActive));

        return _conditions;
    }

    const [conditions, setConditions] = useState<QueryConstraint[]>(mapConditions());
    const [documents, loading, error] =
        useFirebaseCollection("warehouseRequests", conditions, warehouseRequestConverter);
    const [items, setItems] = useState<WarehouseRequestType[]>([]);

    useEffect(
        () => {
            setConditions(mapConditions());
        },
        [createdAtFrom, createdAtTo, isActive]
    );

    useEffect(
        () => {
            if (documents) {
                setItems(documents.docs.map(d => d.data() as WarehouseRequestType));
            }
        },
        [documents]
    );

    return {
        items,
        loading,
        error
    };
}

export const createWarehouseRequest = async (data: WarehouseRequestType) => {
    const warehouseRequestsRef = collection(fbDb, "warehouseRequests");
    const newDoc = await addDoc(warehouseRequestsRef, data);
    return newDoc;
}

export const updateWarehouseRequest = async (data: WarehouseRequestType) => {
    const warehouseRequestsRef = doc(fbDb, `warehouseRequests/${data.id}`);
    const newDoc = await updateDoc(warehouseRequestsRef, data);
    return newDoc;
}
