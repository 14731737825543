import {CustomerOfferServicesType} from "../../../../../../../fb-converters/cutomerOffer-converter";
import {TableRow} from "../product-table/OfferProductPdfTableHeader";
import React from "react";
import {View, Text, StyleSheet} from "@react-pdf/renderer";
import {zeroToEmptyStringAsCurrencyWithCommas} from "../../../../../../../common";
import {getI18Price} from "../../../../../../../i18/i18label";


const styles = StyleSheet.create({
    col1: {width: 280, textAlign: "left", padding: 2, paddingLeft: 22},
    col2: {width: 300, textAlign: "left", padding: 2,},
    col3: {width: 80, textAlign: "center", padding: 2,},
    col4: {width: 80, textAlign: "center", padding: 2,},
    col5: {width: 100, textAlign: "center", padding: 2,},
    col6: {width: 80, textAlign: "center", padding: 2,},
    col7: {width: 80, textAlign: "center", padding: 2,},
});

const OfferProductPdfTableRow = ({services, productQty, euroExchangeRate}:
                                 {
                                     services: CustomerOfferServicesType,
                                     productQty: number,
                                     euroExchangeRate: number,
                                 }) => {
    return (
        <View>
            {
                services.map((service, index) => (
                    <TableRow cols={[
                        <View style={styles.col1}>
                            <Text>
                                {service.service.name}
                            </Text>
                        </View>,
                        <View style={styles.col2}>
                            <Text>
                                {service.service.descriptionForClient}
                            </Text>
                        </View>,
                        <View style={styles.col3}>
                            <Text>
                                {zeroToEmptyStringAsCurrencyWithCommas(getI18Price(service.singlePrice, euroExchangeRate))}
                            </Text>
                        </View>,
                        <View style={styles.col4}>
                            <Text>
                                {zeroToEmptyStringAsCurrencyWithCommas(service.discount)}
                            </Text>
                        </View>,
                        <View style={styles.col5}>
                            <Text>
                                {zeroToEmptyStringAsCurrencyWithCommas(getI18Price(service.finalPrice, euroExchangeRate))}
                            </Text>
                        </View>,
                        <View style={styles.col6}>
                            <Text>
                                {zeroToEmptyStringAsCurrencyWithCommas(service.qty ?? productQty)}
                            </Text>
                        </View>,
                        <View style={styles.col7}>
                            <Text>
                                {zeroToEmptyStringAsCurrencyWithCommas(getI18Price(service.finalPrice * (service.qty ?? productQty), euroExchangeRate))}
                            </Text>
                        </View>
                    ]}/>
                ))
            }
        </View>
    );
};

export default OfferProductPdfTableRow;
