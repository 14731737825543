import {Text, View} from "@react-pdf/renderer";
import React from "react";
import { TableRow } from "../product-table/OfferProductPdfTableHeader";
import {StyleSheet} from "@react-pdf/renderer";
import {zeroToEmptyStringAsCurrencyWithCommas} from "../../../../../../../common";
import {getI18Price, getI18Text} from "../../../../../../../i18/i18label";

const styles = StyleSheet.create({
    col1H: { width: 1150, backgroundColor: "white", textAlign: "right", padding: 2, paddingRight: 22 },
    col7H: { width: 180, backgroundColor: "white", textAlign: "right", padding: 2, paddingRight: 18 },
});

interface OfferProductPdfTableTotalProps {
    total: number;
    euroExchangeRate: number;
}

const OfferProductPdfTableTotal = ({total, euroExchangeRate}: OfferProductPdfTableTotalProps) => {

    return (
        <View style={{ fontWeight: "bold" }}>
            <TableRow cols={[
                <View style={styles.col1H}>
                    <Text>{ getI18Text("Общо с допълнителни разходи") }:</Text>
                </View>,
                <View style={styles.col7H}>
                    <Text>{zeroToEmptyStringAsCurrencyWithCommas(getI18Price(total, euroExchangeRate))}</Text>
                </View>
            ]}/>
        </View>
    );
}

export default OfferProductPdfTableTotal;
