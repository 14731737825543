import {Text, View} from "@react-pdf/renderer";
import {styles} from "./OfferPdfStyles";
import React from "react";
import {getI18Text} from "../../../../../../i18/i18label";

const OfferPdfStaticPage2 = ({i18}: {i18: string[]}) => (
    <View style={{ fontSize: 8 }} wrap={false}>

        <View style={[styles.card, {marginBottom: 0}]}>
            <View style={{ flexDirection: "row" }}>
                <Text style={{ width: "85%"}}>{ i18[20] // Подробно описание на услугата „УДЪЛЖЕНА ГАРАНЦИЯ“
                } </Text>
                {/*<Text style={{ width: "15%", textAlign: "center"}}>{ getI18Text("Цена") }</Text>*/}
            </View>
        </View>

        <View>
            <View style={{ flexDirection: "row" }}>
                <View style={{ width: "85%"}}>
                    <View style={{ flexDirection: "column" }} >
                        <View>
                            <Text style={{ fontWeight: "bold" }}>
                                { i18[21] // Условия на удължената гаранция:
                                }
                            </Text>
                        </View>
                        <View style={{ padding: 10 }}>
                            <Text>
                                { i18[22] // - Удължената гаранция се отнася само за тягови батерии.
                                }
                            </Text>
                            <Text></Text>
                            <Text>

                            </Text><Text>
                            { i18[23] // - Удължената гаранция се предлага на новозакупени продукти или закупени до шест месеца от дата на сключване на договор за удължаване на гаранцията.
                            }
                        </Text><Text>
                            { i18[24] // - Удължената гаранция е за продукти закупени заедно със зарядно устройство от Индустриални Батерии ЕООД.
                            }
                        </Text><Text>
                            { i18[25] // - При експлоатация на продуктите от лек до тежък едносменен дневен режим на работа.
                            }
                        </Text><Text>
                            { i18[26] // - Стриктно спазване на предписанията за експлоатиране на продуктите.
                            }
                        </Text><Text>
                            { i18[27] // - Задължително извършване на ежегодно обслужване на продуктите от сервиз на Индустриални Батерии ЕООД.
                            }
                        </Text><Text>
                            { i18[28] // - При нужда от сервизиране на тягова батерия в сервиз на Индустриални Батерии ЕООД, важат условия и цени по стандартна ценова листа за сервизни услуги.
                            }
                        </Text>
                        </View>
                    </View>
                </View>
                {/*<Text style={{ width: "15%", textAlign: "center"}}>*/}
                {/*    Всички описани услуги са включени в цената за ежегодно, еднократно посещение*/}
                {/*</Text>*/}
            </View>
        </View>



        <View style={[styles.card, {marginBottom: 0}]}>
            <View style={{ flexDirection: "row" }}>
                <Text style={{ width: "100%"}}>{ i18[29] // Подробно описание на услугата „АБОНАМЕНТНА ПОДДРЪЖКА“
                }</Text>
                {/*<Text style={{ width: "15%", textAlign: "center"}}>{ getI18Text("Цена") }</Text>*/}
            </View>
        </View>

        <View>
            <View style={{ flexDirection: "row" }}>
                <View style={{ width: "85%"}}>
                    <View style={{ flexDirection: "column" }} >
                        <View>
                            <Text style={{ fontWeight: "bold" }}>
                                { i18[30] // Описание на включените услуги в абонаментно обслужване:
                                }
                            </Text>
                        </View>
                        <View style={{ padding: 10 }}>
                            <Text>
                                { i18[31] // - Първоначално след подписване на договор за абонаментно обслужване:
                                }
                            </Text>
                            <View style={{ paddingLeft: 10}}>
                                <Text>
                                    { i18[32] // - Първоначален анализ на текущата експлоатация и поддръжка на тяговите батерии.
                                    }
                                </Text>
                                <Text>
                                    { i18[33] // - Консултация и предписание относно правилна експлоатация на продуктите, нужда от превантивни и коригиращи действия.
                                    }
                                </Text>
                                <Text>
                                    { i18[34] // - Обучение на обслужващия персонал относно правилна експлоатация на продуктите.
                                    }
                                </Text>
                            </View>
                            <Text></Text>
                            <Text></Text>
                            <Text>{ i18[35] // - При всяко посещение:
                            }</Text>
                            <View style={{ paddingLeft: 10}}>
                                <Text>
                                    { i18[36] // &bull; Акумулаторни батерии (по-долу в текста АБ):
                                    }
                                </Text>
                                <View style={{ paddingLeft: 10}}>
                                    <Text>
                                        { i18[37] // - Пускане в експлоатация на нова АБ.
                                        }
                                    </Text>
                                    <Text>
                                        { i18[38] // - Проверка на техническото състояние на АБ.
                                        }
                                    </Text>
                                    <Text>
                                        { i18[39] // - Ремонт АБ.
                                        }
                                    </Text>
                                </View>

                                <Text>
                                    { i18[40] // &bull;  Зарядни устройства (по-долу в текста ЗУ).
                                    }
                                </Text>
                                <View style={{ paddingLeft: 10}}>
                                    <Text>
                                        { i18[41] // - Пускане в експлоатация (настройка на конфигурацията, проверка на работоспособността).
                                        }
                                    </Text>
                                    <Text>
                                        { i18[42] // - Почистване, включително и на скрити кухини.
                                        }
                                    </Text>
                                    <Text>
                                        { i18[43] // - Ремонт на ЗУ.
                                        }
                                    </Text>
                                    <Text>
                                        { i18[44] // - Снемане на данните в паметта, изготвяне на отчет/справка.
                                        }
                                    </Text>
                                </View>
                            </View>
                            <Text>{ i18[45] // - Транспорт на сервизния екип до адрес на клиента.
                            } </Text>
                            <Text>{ i18[46] // - При нужда от сервизиране на тягова батерия в сервиз на Индустриални Батерии ЕООД, важат условия и цени по стандартна ценова листа за сервизни услуги, Приложение 5 към Договор за техническо абонаментно обслужване.
                            }</Text>
                        </View>
                    </View>
                </View>
                {/*<View style={{ width: "15%", textAlign: "center"}}>*/}
                {/*    <Text>{ i18[60] // Цена за еднократно посещение:*/}
                {/*    }</Text>*/}
                {/*    <Text style={{ marginTop: 15 }}>*/}
                {/*        __ V { getI18Text("батерия") }:</Text>*/}
                {/*    <Text style={{ marginTop: 5 }}>*/}
                {/*        __.__ { getI18Text("в./бр.") }*/}
                {/*    </Text>*/}
                {/*</View>*/}
            </View>
        </View>
    </View>

);

export default OfferPdfStaticPage2;
