import {
    CustomerOfferProductionAttributesType,
} from "../../../../../../../fb-converters/cutomerOffer-converter";
import React from "react";
import OfferAttrPdfTableHeader from "./OfferAttrPdfTableHeader";
import OfferAttrPdfTableRow from "./OfferAttrPdfTableRow";
import {View} from "@react-pdf/renderer";
import OfferAttrPdfTableTotal from "./OfferAttrPdfTableTotal";

interface OfferPdfTableProps {
    attributes: CustomerOfferProductionAttributesType;
    productQty: number;
    total: number;
    euroExchangeRate: number;
}

const OfferProductPdfTable = ({attributes, productQty, total, euroExchangeRate}: OfferPdfTableProps) => {
    return (<View wrap={false}>
        <OfferAttrPdfTableHeader/>
        <OfferAttrPdfTableRow attributes={attributes} productQty={productQty} euroExchangeRate={euroExchangeRate}/>
        <OfferAttrPdfTableTotal total={total} euroExchangeRate={euroExchangeRate} />
    </View>);
};

export default OfferProductPdfTable;
