import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalTitle, Row, Spinner} from "react-bootstrap";
import {ExpeditionStatusType, ExpeditionType} from "../../../fb-converters/expedition-converter";
import React, {useEffect, useMemo, useState} from "react";
import ModalHeader from "react-bootstrap/ModalHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faTruck} from "@fortawesome/free-solid-svg-icons";
import DialogCloseButton from "../../../common/DialogCloseButton";
import ModalBackground from "../../../common/ModalBackground";
import ProductionPlan from "../production-plan/ProductionPlan";
import ProductionPlanDialog from "../production-plan/ProductionPlanDialog";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import {ProductionCardType} from "../types/ProductionCardType";
import {dateToString, textToFloat, textToInt} from "../../../common";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import {fbDb} from "../../../App";
import {customerInquiryConverter} from "../../../fb-converters/cutomerInquiry-converter";
import ReactDatePicker from "react-datepicker";
import {UsersDialog} from "../../nomenclatures/users/UsersDialog";
import {CarsDialog} from "../../nomenclatures/cars/CarsDialog";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {ProductType} from "../../nomenclatures/products/hooks/useProductsOnce";
import {ProductsDialog} from "../../nomenclatures/products/ProductsDialog";
import I18Label from "../../../i18/i18label";

interface ExpeditionDetailsProps {
    zIndex?: number,
    show?: boolean,
    expeditionToEdit: ExpeditionType,
    onDone: (newExpedition: ExpeditionType) => void,
    onCancel: () => void,
    saving?: boolean,
}

const ExpeditionDetails = ({
                               zIndex = 1050,
                               show = false,
                               expeditionToEdit,
                               onDone,
                               onCancel,
                               saving
                           }: ExpeditionDetailsProps) => {
    const allProducts = useHookstate(appDb.products).value.map((p) => p as ProductType);
    const [expedition, setExpedition] = useState<ExpeditionType>(expeditionToEdit);
    const [selectedCard, setSelectedCard] = useState<ProductionCardType | undefined>();

    const [editQty, setEditQty] = useState(expeditionToEdit.productQty?.toString() ?? "");
    const [editParcels, setEditParcels] = useState(expeditionToEdit.weights?.leaving?.parcels?.toString() ?? "");
    const [editPallets, setEditPallets] = useState(expeditionToEdit.weights?.leaving?.pallets?.toString() ?? "");
    const [editScrap, setEditScrap] = useState(expeditionToEdit.weights?.arriving?.scrap?.toString() ?? "");
    const [editDelivery, setEditDelivery] = useState(expeditionToEdit.weights?.arriving?.delivery?.toString() ?? "");
    const [editPaletsLeaving, setEditPaletsLeaving] = useState(expeditionToEdit.palets?.leaving?.toString() ?? "");
    const [editPaletsArriving, setEditPaletsArriving] = useState(expeditionToEdit.palets?.arriving?.toString() ?? "");

    useEffect(
        () => {
            setExpedition({...expeditionToEdit});
            setEditQty(expeditionToEdit.productQty?.toString() ?? "");
            setEditParcels(expeditionToEdit.weights?.leaving?.parcels?.toString() ?? "");
            setEditPallets(expeditionToEdit.weights?.leaving?.pallets?.toString() ?? "");
            setEditScrap(expeditionToEdit.weights?.arriving?.scrap?.toString() ?? "");
            setEditDelivery(expeditionToEdit.weights?.arriving?.delivery?.toString() ?? "");
            setEditPaletsLeaving(expeditionToEdit.palets?.leaving?.toString() ?? "");
            setEditPaletsArriving(expeditionToEdit.palets?.arriving?.toString() ?? "");
        },
        [expeditionToEdit]
    );

    useEffect(() => {
        setExpedition({
            ...expedition,
            productQty: textToInt(editQty, 1),
            palets: {
                leaving: textToInt(editPaletsLeaving, 0),
                arriving: textToInt(editPaletsArriving, 0)
            },
            weights: {
                leaving: {
                    parcels: textToFloat(editParcels, 0),
                    pallets: textToFloat(editPallets, 0)
                },
                arriving: {
                    scrap: textToFloat(editScrap, 0),
                    delivery: textToFloat(editDelivery, 0)
                }
            }
        });
    }, [editParcels, editPallets, editScrap, editDelivery, editQty, editPaletsArriving, editPaletsArriving]);

    const productionPlanDialog = <ProductionPlanDialog show={true} zIndex={zIndex + 1} onClose={() => {
    }}/>;
    const usersDialog = <UsersDialog show={true} zIndex={1060} onClose={() => {
    }}/>;
    const cardsDialog = <CarsDialog show={true} zIndex={1060} onClose={() => {
    }}/>;
    const productsDialog = <ProductsDialog show={true} zIndex={1060} onClose={() => {
    }}/>;

    function onSelectedCard(selected: ProductionCardType) {
        if (!selected) {
            setSelectedCard(undefined);
            setExpedition(
                {
                    ...expedition,
                    cardId: null,
                    cardNumber: null,
                    cardDate: null,
                    invoiceClientName: null,
                    inquiry: null,
                    client: null,
                    productId: null,
                    productName: null,
                    productQty: null,
                    productSku: null,
                }
            );
            return;
        }

        setSelectedCard(selected);
        const qtyLeft = selected.specification[0].productQty -
            (selected.expeditions?.reduce((acc, exp) => acc + (exp.qty ?? 0), 0) ?? 0);
        const updatedExp = {
            ...expedition,
            cardId: selected.id ?? null,
            cardNumber: selected.cardNumber.toString() ?? null,
            cardDate: selected.cardDate ?? null,
            invoiceClientName: selected.invoiceClientName ?? null,
            inquiry: {
                id: selected.inquiryId ?? null,
                inquiryDate: selected.inquiryDate ?? null,
                inquiryNumber: selected.inquiryNumber?.toString() ?? null
            },
            productId: selected.productId ?? null,
            productQty: qtyLeft
        };

        if (updatedExp.productId) {
            const product = allProducts.find((p) => p.id === updatedExp.productId);
            updatedExp.productName = product?.name ?? null;
            updatedExp.productSku = product?.sku ?? null;
            updatedExp.weights = {
                leaving: {
                    parcels: product?.weightWithElectrolit ? product.weightWithElectrolit : 0,
                    pallets: 0
                },
                arriving: {
                    scrap: 0,
                    delivery: 0
                }
            };
        } else {
            updatedExp.productName = null;
            updatedExp.productSku = null;
        }

        setExpedition(updatedExp);

        window.setTimeout(() => {
            setEditParcels(updatedExp.weights?.leaving?.parcels?.toString() ?? "");
            setEditScrap("0");
            setEditDelivery("0");
            setEditPallets("0");
            setEditQty(updatedExp.productQty?.toFixed(2) ?? "");
        }, 100);
    }

    function getOrderLabel(expedition: ExpeditionType) {
        if (!expedition || !expedition.cardId) {
            return "";
        }
        return `Поръчка No: ${expedition.cardNumber} от ${dateToString(expedition.cardDate)}. Клиент: ${expedition.invoiceClientName}`;
    }

    useEffect(
        () => {
            if (!expedition.inquiry?.id) {
                return;
            }
            const inquiryDocument = doc(fbDb, 'customerInquiries', expedition.inquiry.id).withConverter(customerInquiryConverter);
            getDoc(inquiryDocument).then((snapshot) => {
                const inquiry = snapshot.data();
                setExpedition(
                    {
                        ...expedition,
                        inquiry: {
                            inquiryNumber: inquiry?.inquiryNumber.toString() ?? null,
                            inquiryDate: inquiry?.inquiryDate ?? null,
                            id: expedition.inquiry?.id ?? null
                        },
                        client: {
                            id: inquiry?.client?.id ?? null,
                            name: inquiry?.client?.name ?? null,
                            region: inquiry?.client?.region ?? null,
                            address: inquiry?.client?.address ?? null,
                            contactPerson: inquiry?.client?.mol ?? null,
                            phone: inquiry?.client?.phone ?? null,
                        },
                    }
                );
            });
        },
        [expedition?.inquiry?.id]
    );

    function validateInput() {
        let isValid = true;
        if (!expedition.expeditionDate) {
            isValid = false;
        }
        if (!expedition.driver || !expedition.driver.id) {
            isValid = false;
        }
        if (!expedition.car || !expedition.car.id) {
            isValid = false;
        }
        if (expeditionTotalWeight > (expedition.car?.maxWeight ?? 0)) {
            isValid = false;
        }
        return isValid;
    }

    const onSave = () => {
        expedition.productQty = textToFloat(editQty, 0);
        expedition.palets = {
            leaving: textToFloat(editPaletsLeaving, 0),
            arriving: textToFloat(editPaletsArriving, 0)
        };
        expedition.weights = {
            leaving: {
                parcels: textToFloat(editParcels, 0),
                pallets: textToFloat(editPallets, 0)
            },
            arriving: {
                scrap: textToFloat(editScrap, 0),
                delivery: textToFloat(editDelivery, 0)
            }
        };

        onDone(expedition);
    }

    function getExpeditionTotalWeight() {
        let totalWeight = 0;
        totalWeight += textToFloat(expedition.weights?.leaving?.parcels ?? "0") ?? 0;
        totalWeight += textToFloat(expedition.weights?.arriving?.delivery ?? "0") ?? 0;
        totalWeight += textToFloat(expedition.weights?.arriving?.scrap ?? "0") ?? 0;
        return totalWeight;
    }

    const expeditionTotalWeight = useMemo(
        () => getExpeditionTotalWeight(),
        [expedition.weights?.leaving?.parcels, expedition.weights?.arriving?.delivery, expedition.weights?.arriving?.scrap]
    );

    return (
        <>
            <ModalBackground zIndex={zIndex}>
                <Modal show={show} size={"xl"} style={{zIndex: (zIndex + 1)}}>
                    <ModalHeader>
                        <ModalTitle>
                            <FontAwesomeIcon icon={faTruck}/> Детайли за експедиция
                        </ModalTitle>
                        <DialogCloseButton onClose={() => onCancel()}/>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs={"auto"}>
                                <Form.Group>
                                    <Form.Label>
                                        Дата на експедиция *:
                                    </Form.Label><br/>
                                    <ReactDatePicker
                                        className={"form-control"}
                                        selected={expedition.expeditionDate}
                                        dateFormat={"dd.MM.yyyy"}
                                        isClearable={true}
                                        onChange={
                                            (date) => setExpedition({
                                                ...expedition,
                                                expeditionDate: date
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Шофьор *:
                                    </Form.Label>
                                    <SelectValueFromDialog
                                        value={expedition.driver?.name || ""}
                                        dialog={usersDialog}
                                        onChange={(selected) => {
                                            setExpedition({
                                                ...expedition,
                                                driver: {
                                                    id: selected.id,
                                                    name: selected.name
                                                }
                                            });
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Превозно средство *:
                                    </Form.Label>
                                    <SelectValueFromDialog
                                        value={`${expedition.car?.name || ""} ${expedition.car?.regPlate ? `[${expedition.car?.regPlate}]` : ""}`}
                                        dialog={cardsDialog}
                                        onChange={(selected) => {
                                            setExpedition({
                                                ...expedition,
                                                car: {
                                                    id: selected.id,
                                                    name: selected.name,
                                                    regPlate: selected.regPlate,
                                                    maxWeight: selected.maxWeight
                                                }
                                            });
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={2}>
                                <Form.Group>
                                    <Form.Label>
                                        Статус:
                                    </Form.Label>
                                    <Form.Select
                                        value={expedition.status ?? undefined}
                                        onChange={
                                            (e) => setExpedition({
                                                ...expedition,
                                                status: e.target.value === "" ? null : e.target.value as ExpeditionStatusType
                                            })
                                        }
                                    >
                                        <option value={""}>Изберете статус</option>
                                        <option value={"ПЛАНИРАНА"}>Планирана</option>
                                        <option value={"ИЗПЪЛНЯВА СЕ"}>Изпълнява се</option>
                                        <option value={"ЗАВЪРШЕНА"}>Завършена</option>
                                        <option value={"ОТКАЗАНА"}>Отказана</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr/>
                        <Form.Group>
                            <Form.Label>
                                Изберете поръчка
                            </Form.Label>
                            <SelectValueFromDialog
                                value={getOrderLabel(expedition)}
                                dialog={productionPlanDialog}
                                onChange={(selected) => {
                                    onSelectedCard(selected);
                                }}
                            />
                        </Form.Group>
                        <hr/>
                        <Row>
                            <Col xs={"auto"}>
                                <Form.Group>
                                    <Form.Label>
                                        Запитване:
                                    </Form.Label>
                                    <Form.Control type={"text"} value={expedition.inquiry?.inquiryNumber || ""}
                                                  disabled={expedition.inquiry !== null && expedition.inquiry.id !== ""}
                                                  onChange={
                                                      (e) => setExpedition({
                                                          ...expedition,
                                                          inquiry: {
                                                              id: expedition.inquiry?.id ?? "",
                                                              inquiryDate: expedition.inquiry?.inquiryDate ?? null,
                                                              inquiryNumber: e.target.value
                                                          }
                                                      })
                                                  }
                                                  onBlur={
                                                      (e) => setExpedition({
                                                          ...expedition,
                                                          inquiry: {
                                                              id: expedition.inquiry?.id ?? "",
                                                              inquiryDate: expedition.inquiry?.inquiryDate ?? null,
                                                              inquiryNumber: textToInt(e.target.value, expeditionToEdit.inquiry?.inquiryNumber ?? null)?.toString() ?? null
                                                          }
                                                      })
                                                  }
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={"auto"}>
                                <Form.Group>
                                    <Form.Label>
                                        Дата на запитване:
                                    </Form.Label><br/>
                                    <ReactDatePicker
                                        className={"form-control"}
                                        selected={expedition.inquiry?.inquiryDate}
                                        dateFormat={"dd.MM.yyyy"}
                                        isClearable={true}
                                        disabled={expedition.inquiry !== null && expedition.inquiry.id !== ""}
                                        onChange={
                                            (date) => setExpedition({
                                                ...expedition,
                                                inquiry: {
                                                    id: expedition.inquiry?.id ?? "",
                                                    inquiryDate: date,
                                                    inquiryNumber: expedition.inquiry?.inquiryNumber ?? null
                                                }
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        <I18Label label={"Клиент"} />:
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={expedition.client?.name || ""}
                                        disabled={expedition.client !== null && expedition.client.id !== ""}
                                        onChange={
                                            (e) => setExpedition({
                                                ...expedition,
                                                client: {
                                                    id: expedition.client?.id ?? "",
                                                    name: e.target.value,
                                                    region: expedition.client?.region ?? null,
                                                    address: expedition.client?.address ?? null,
                                                    contactPerson: expedition.client?.contactPerson ?? null,
                                                    phone: expedition.client?.phone ?? null,
                                                }
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={2}>
                                <Form.Group>
                                    <Form.Label>
                                        Посока:
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={expedition.client?.region || ""}
                                        disabled={expedition.client !== null && expedition.client.id !== ""}
                                        onChange={
                                            (e) => setExpedition({
                                                ...expedition,
                                                client: {
                                                    id: expedition.client?.id ?? "",
                                                    name: expedition.client?.name ?? null,
                                                    region: e.target.value,
                                                    address: expedition.client?.address ?? null,
                                                    contactPerson: expedition.client?.contactPerson ?? null,
                                                    phone: expedition.client?.phone ?? null,
                                                }
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Адрес:
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={expedition.client?.address || ""}
                                        onChange={
                                            (e) => setExpedition({
                                                ...expedition,
                                                client: {
                                                    id: expedition.client?.id ?? "",
                                                    name: expedition.client?.name ?? null,
                                                    region: expedition.client?.region ?? null,
                                                    address: e.target.value,
                                                    contactPerson: expedition.client?.contactPerson ?? null,
                                                    phone: expedition.client?.phone ?? null,
                                                }
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group>
                                    <Form.Label>
                                        Лице за контакт:
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={expedition.client?.contactPerson || ""}
                                        onChange={
                                            (e) => setExpedition({
                                                ...expedition,
                                                client: {
                                                    id: expedition.client?.id ?? "",
                                                    name: expedition.client?.name ?? null,
                                                    region: expedition.client?.region ?? null,
                                                    address: expedition.client?.address ?? null,
                                                    contactPerson: e.target.value,
                                                    phone: expedition.client?.phone ?? null,
                                                }
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={2}>
                                <Form.Group>
                                    <Form.Label>
                                        Телефон:
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={expedition.client?.phone || ""}
                                        onChange={
                                            (e) => setExpedition({
                                                ...expedition,
                                                client: {
                                                    id: expedition.client?.id ?? "",
                                                    name: expedition.client?.name ?? null,
                                                    region: expedition.client?.region ?? null,
                                                    address: expedition.client?.address ?? null,
                                                    contactPerson: expedition.client?.contactPerson ?? null,
                                                    phone: e.target.value,
                                                }
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Продукт:
                                    </Form.Label>
                                    {
                                        selectedCard ?
                                            <Form.Control
                                                type={"text"}
                                                value={expedition.productName || ""}
                                                disabled={true}
                                            />
                                            :
                                            <SelectValueFromDialog
                                                value={expedition.productName || ""}
                                                dialog={productsDialog}
                                                onChange={(selected) => {
                                                    setExpedition({
                                                        ...expedition,
                                                        productId: selected.id,
                                                        productName: selected.name,
                                                        productSku: selected.sku,
                                                    });
                                                    setEditParcels(selected.weightWithElectrolit.toString());
                                                    setEditScrap("0");
                                                    setEditDelivery("0");
                                                    setEditPallets("0");
                                                }}
                                            />
                                    }
                                </Form.Group>
                            </Col>
                            <Col xs={2}>
                                <Form.Group>
                                    <Form.Label>
                                        SKU:
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={expedition.productSku || ""}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Количество:
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={editQty}
                                        onChange={
                                            (e) => setEditQty(e.target.value)
                                        }
                                        onBlur={
                                            (e) => {
                                                const newQty = textToFloat(e.target.value, expeditionToEdit.productQty ?? 0) ?? 0;
                                                const newExp = {...expedition};
                                                newExp.productQty = newQty;
                                                setExpedition(newExp);
                                                setEditQty(newQty.toString());
                                            }
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Пратка(кг):
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={editParcels}
                                        onChange={
                                            (e) => setEditParcels(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            {/*<Col>*/}
                            {/*    <Form.Group>*/}
                            {/*        <Form.Label>*/}
                            {/*            Палети(кг):*/}
                            {/*        </Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            type={"text"}*/}
                            {/*            value={editPallets}*/}
                            {/*            onChange={*/}
                            {/*                (e) => setEditPallets(e.target.value)*/}
                            {/*            }*/}
                            {/*        />*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Скрап(кг):
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={editScrap}
                                        onChange={
                                            (e) => setEditScrap(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Доставка(кг):
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={editDelivery}
                                        onChange={
                                            (e) => setEditDelivery(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Палети (заминаване):
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={editPaletsLeaving}
                                        onChange={
                                            (e) => setEditPaletsLeaving(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Палети (връщане):
                                    </Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={editPaletsArriving}
                                        onChange={
                                            (e) => setEditPaletsArriving(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        Бележки:
                                    </Form.Label>
                                    <Form.Control
                                        as={"textarea"}
                                        value={expedition.notes || ""}
                                        onChange={
                                            (e) => setExpedition({
                                                ...expedition,
                                                notes: e.target.value
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {
                            saving ?
                                <Row>
                                    <Col className={"text-center"}>
                                        <Spinner animation={"border"}/>
                                    </Col>
                                </Row>
                                : <>
                                    <Button disabled={!validateInput()} onClick={onSave}>Запази</Button>
                                    <Button onClick={onCancel} variant={"secondary"}>Откажи</Button>
                                </>
                        }
                    </ModalFooter>
                </Modal>
            </ModalBackground>
        </>
    );
}

export default ExpeditionDetails;
