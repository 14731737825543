import {Link, Text, View, Image as PdfFImage} from "@react-pdf/renderer";
import {styles} from "./OfferPdfStyles";
import whyToChooseBG from "../../../../../../assets/images/why_to_choose_bg.png";
import whyToChooseRO from "../../../../../../assets/images/why_to_choose_ro.png";
import React from "react";

const OfferPdfStaticPage1 = ({i18, lng} : {i18: string[], lng: string}) => (
    <View wrap={false} style={{ marginLeft: 30, lineHeight: 1.75, fontSize: 10 }}>
        <Text style={{ fontWeight: "bold" }}>
            &bull;{ i18[50]  // Допълнителна информация към офертата:
            }
        </Text>
        <Text style={styles.deliveryItem}>
            { i18[51] //Техническа скица на батерията (прикачен файл)
        }
        </Text>
        <View style={[styles.deliveryItem, { paddingTop: 10 }]}>
            <Text style={{ fontWeight: "bold" }}>
                { i18[52] //Защо да изберете „Индустриални батерии“ ЕООД за ваш доставчик:
                }
            </Text>
            <PdfFImage src={lng === "bg" ? whyToChooseBG : whyToChooseRO} style={{ width: "75%", margin: 5 }} />
            <Text>
                • { i18[53] //Оптимален експлоатационен срок на батерията и изгодна обща цена на притежание
            }
            </Text>
            <Text>
                • { i18[54] // Над 20 години опит в производството на индустриални батерии
            }
            </Text>
            <Text>
                • { i18[55] // Световни сертификати ISO 9001:2015 и ISO 14001:2015
            }
            </Text>
            <Text>
                • { i18[56] // Качествени компоненти от европейски доставчици
            }
            </Text>
            <Text>
                • { i18[57] // Финално асемблиране в България
            }
            </Text>
            <Text>
                • { i18[58] // Безупречно гаранционно обслужване
            }
            </Text>
            <Text> </Text>
            <View style={[styles.deliveryItem, {  }]}>
                <Text>
                    {i18[59]  // За повече информация
                    }: <Link>https://industrialbatteries.bg/{lng}</Link>
                </Text>
            </View>
        </View>
    </View>
);

export default OfferPdfStaticPage1;
